import cities_selection_fr_FR from './data/cities_selection_fr_FR.json';
import cities_selection_de_DE from './data/cities_selection_de_DE.json';
import cities_selection_fr_BE from './data/cities_selection_fr_BE.json';

/**
 * Get the cities selection based on the country code.
 *
 * @param {string} locale - The locale to determine which cities data to return.
 * @returns {Array<Object>} - The list of cities for the given country code.
 */
export const getCitiesSelection = locale => {
	switch (locale) {
		case 'fr-BE':
			return cities_selection_fr_BE;
		case 'de-DE':
			return cities_selection_de_DE;
		default:
			return cities_selection_fr_FR;
	}
};
