import i18n from 'i18next';
import { AVAILABLE_LANGUAGES, de_DE, fr_FR } from '@planity/localization';
import { initReactI18next } from 'react-i18next';
import 'intl-pluralrules';

const options = {
	fallbackLng: 'fr',
	supportedLngs: AVAILABLE_LANGUAGES,
	load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE
	// have a common namespace used around the full app

	debug: process.env.NODE_ENV === 'development' && process.env.BROWSER,
	interpolation: {
		escapeValue: false,
		prefix: '${',
		suffix: '}'
	},
	returnObjects: true,
	resources: {
		fr: { translation: fr_FR },
		de: { translation: de_DE }
	},
	react: {
		useSuspense: false
	}
};

// for browser use http backend to load translations and browser lng detector
// not sure about this....🤷‍
if (process.env.BROWSER) {
	i18n.use(initReactI18next);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
	i18n.init(options);
}

export default i18n;
