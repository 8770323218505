import credentials from '@planity/credentials';
import { formatPrice } from '@planity/helpers';
import { StepDescription, FeesDescription } from '@planity/ui';
import { differenceInHours, parseISO } from 'date-fns';
import { Localize } from '@planity/components/';
import React from 'react';
import styles from './cancellation_modal_content.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';

const { ENABLE_USER_PAYS_FEES } = credentials;

export const CancellationModalContent = ({
	className,
	appointment,
	business,
	feesAmount
}) => {
	const { start, sequence } = appointment;
	const userPaysFees = !!(ENABLE_USER_PAYS_FEES && appointment.userPaysFees);
	const fees = userPaysFees ? feesAmount : 0;

	const hasDepositAmount = sequence.some(
		({ depositMode }) => depositMode === 'amount'
	);

	useStyles(styles);
	const cx = classNames.bind(styles);

	return (
		<div
			className={cx(
				{
					cancellationModal: true
				},
				className
			)}
		>
			<div className={styles.cancelAppointmentModal}>
				<p className={styles.modalTitle} id='cancel-appointment-title'>
					<Localize text={'myAccount.appointment.cancelAppointment'} />
				</p>
				<div className={styles.stepContainer}>
					{Object.entries(sequence || {})
						.filter(([, { price, cancelRate }]) => !!(price && cancelRate))
						.map(
							(
								[
									stepId,
									{
										cancelRate,
										price,
										lateCancellationDelay,
										isDeposit,
										depositMode
									}
								],
								index
							) => {
								const appointmentStart = parseISO(start);
								const canBeCancelled = !!start && appointmentStart > new Date();

								const getText = () => {
									if (isDeposit === 'partial')
										return 'myAccount.appointment.appointmentCancellation.deposit.text';
									if (hasDepositAmount)
										return 'myAccount.appointment.prepaymentCancelFeesAmount';
									return 'myAccount.appointment.prepaymentCancelFees';
								};

								const cancelAmount =
									depositMode === 'amount'
										? formatPrice(cancelRate)
										: formatPrice((price / 100) * cancelRate);

								return (
									<div className={styles.stepContent} key={stepId}>
										<StepDescription
											business={business}
											canBeCancelled={canBeCancelled}
											index={index}
											key={stepId}
											step={sequence[stepId]}
											twoRows
											noBorder={true}
										/>
										{differenceInHours(appointmentStart, new Date()) <
											lateCancellationDelay && (
											<div className={styles.amountDescription}>
												<p
													className={styles.cancel}
													id={`cancellation-text-${stepId}`}
												>
													{appointment?.chargeId ? (
														<Localize
															args={{
																cancelRate,
																price: formatPrice(price),
																cancelAmount
															}}
															text={getText()}
														/>
													) : (
														<Localize
															args={{
																cancelRate,
																price1: formatPrice(price),
																price2: cancelAmount
															}}
															text={'myAccount.appointment.cancelFees'}
														/>
													)}
												</p>
											</div>
										)}
									</div>
								);
							}
						)}
					{fees > 0 && <FeesDescription fees={fees} isBold />}
				</div>
			</div>
		</div>
	);
};
