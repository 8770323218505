import {
	AppointmentPreview,
	ErrorMessage,
	Localize,
	PhoneInput,
	withAuth
} from '@planity/components';
import { invokeLambda, noop, scrollTo } from '@planity/helpers';
import { withLocalization, withTranslation } from '@planity/localization';
import { Button, Card, Container, Spinner } from '@planity/ui';
import { sendToUserApp } from '@planity/webview';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Layout } from '../layout';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './index.module.scss';
import { withOnlinePaymentData } from '@planity/context/user_data_context/use_online_appointment_fetcher';
import { withStripeFees } from '@planity/components/app_settings/stripeFeesProvider';

const MISSING_NUMBER_ERROR = 'MISSING_NUMBER_ERROR';
const INVALID_NUMBER_ERROR = 'INVALID_NUMBER_ERROR';
const INVALID_CODE_NUMBER_PAIR_ERROR = 'INVALID_CODE_NUMBER_PAIR_ERROR';
const NETWORK_ERROR = 'NETWORK_ERROR';
const WHITELISTED_ERRORS = [
	MISSING_NUMBER_ERROR,
	INVALID_NUMBER_ERROR,
	INVALID_CODE_NUMBER_PAIR_ERROR,
	NETWORK_ERROR
].map(e => `manageAppointment.errors.${e}`);

const isWhiteLabelWebsiteShortCode = ({ url }) =>
	['b', 'd', 'f', 'h'].some(shortCode => url.includes(`/${shortCode}/`));
const isDeDeShortCode = ({ url }) =>
	['c', 'd'].some(shortCode => url.includes(`/${shortCode}/`));
const isFrBeShortCode = ({ url }) =>
	['e', 'f'].some(shortCode => url.includes(`/${shortCode}/`));

/**
 * /a/ : French and normal website
 * /b/ : French and white label website
 *
 * /c/ : German and normal website
 * /d/ : German and white label website
 *
 * /e/ : Belgium (fr) and normal website
 * /f/ : Belgium (fr) and white label website
 *
 * @return {React.Component}
 */
export default withStripeFees(
	withLocalization(
		withAuth(
			withTranslation()(
				withStyles(styles)(
					class ManageAppointmentPage extends Component {
						state = {
							status: 'pending',
							phoneNumber: '',
							error: null
						};

						componentDidMount() {
							const { user, isLoading, match } = this.props;
							const { shortCode } = match.params;
							const isFromReminder = isPhoneCode(shortCode);
							if (
								this.state.status === 'pending' &&
								user?.phone &&
								!isLoading
							) {
								this.setState(
									{
										status: initialStatus(this.props),
										phoneNumber: isFromReminder ? user.phone : null
									},
									() => this.verify(null, isFromReminder)
								);
							} else if (
								this.state.status === 'pending' &&
								!user &&
								!isLoading
							) {
								this.setState({
									status: initialStatus(this.props)
								});
							}
						}

						componentDidUpdate(prevProps) {
							const { user, isLoading, match } = this.props;
							const { shortCode } = match.params;
							const isFromReminder = isPhoneCode(shortCode);

							if (
								user?.phone &&
								!isLoading &&
								prevProps.isLoading !== isLoading
							) {
								this.setState(
									{
										status: initialStatus(this.props),
										phoneNumber: isFromReminder ? user.phone : null
									},
									() => this.verify(null, isFromReminder)
								);
							} else if (
								!user &&
								!isLoading &&
								prevProps.isLoading !== isLoading
							) {
								this.setState(
									{
										status: initialStatus(this.props)
									},
									() => !isFromReminder && this.verify(null)
								);
							}
						}

						render() {
							const { status, phoneNumber, error, appointment } = this.state;
							const { history, t, match, countryCode, localizedBasePath } =
								this.props;
							const { shortCode, locale: urlLocale } = match.params;
							const hideHeader = isWhiteLabelWebsiteShortCode(match);

							const isPending = status === 'pending';
							const wasVerified = status === 'success';

							if (isDeDeShortCode(match) && !urlLocale) {
								// Side effect should not happen in render. But this one seems safe
								process.env.BROWSER &&
									sendToUserApp({
										type: '300_REDIRECTION',
										payload: {
											to: `${window.location.origin}/de-DE${match.url}`
										}
									});
								return <Redirect to={`/de-DE${match.url}`} />;
							}
							if (isFrBeShortCode(match) && !urlLocale) {
								// Side effect should not happen in render. But this one seems safe
								process.env.BROWSER &&
									sendToUserApp({
										type: '300_REDIRECTION',
										payload: {
											to: `${window.location.origin}/fr-BE${match.url}`
										}
									});
								return <Redirect to={`/fr-BE${match.url}`} />;
							}

							return (
								<Layout
									hasBackgroundColor
									hideBreadcrumbs
									hideHeader={hideHeader}
									isDark
								>
									<Helmet
										defaultTitle={
											hideHeader
												? t('meta.manageAppointment.title.headerHidden')
												: t('meta.manageAppointment.title.headerShown')
										}
									>
										<meta content={'noindex,nofollow'} name={'robots'} />
										<meta content={'none'} name={'og:image'} />
										{hideHeader && (
											<meta
												content={t('meta.manageAppointment.title.headerHidden')}
												name={'og:title'}
											/>
										)}
										{hideHeader && (
											<meta
												content={t('meta.manageAppointment.description')}
												property={'og:description'}
											/>
										)}
									</Helmet>
									<div>
										<Container>
											<div
												className={`
													${styles.manageAppointment}
													${wasVerified && styles.manageAppointmentVerified}
												`}
											>
												<ErrorMessage
													defaultMessage={
														'manageAppointment.errors.defaultError'
													}
													error={error && `manageAppointment.errors.${error}`}
													whitelist={WHITELISTED_ERRORS}
												/>
												{isPending ? (
													<div className={styles.loader}>
														<Spinner />
													</div>
												) : wasVerified ? (
													<div className={styles.appointmentCard}>
														<AppointmentPreview
															appointment={appointment}
															appointmentId={appointment.sequenceId}
															authWith={{
																shortCode,
																phoneNumber
															}}
															collapseAppointmentSteps
															disableOnlinePaymentInfo
															disableRating
															linkToBusiness={noop}
														/>
														{!isWhiteLabelWebsiteShortCode && (
															<div className={styles.appointment}>
																<div className={styles.appointmentText}>
																	<p>
																		<Localize
																			text={
																				'manageAppointment.nextAppointment1'
																			}
																		/>
																	</p>
																	<p>
																		<Localize
																			text={
																				'manageAppointment.nextAppointment2'
																			}
																		/>
																	</p>
																</div>

																<Button
																	className={styles.mobile}
																	isFullWidth
																	label={t('manageAppointment.newAppointment')}
																	size='mobile'
																	type={'primary'}
																	onClick={() =>
																		history.push(
																			`${localizedBasePath}/${appointment.business.slug}`
																		)
																	}
																/>
																<Button
																	className={styles.tablet}
																	label={t('manageAppointment.newAppointment')}
																	size='large'
																	type={'primary'}
																	onClick={() =>
																		history.push(
																			`${localizedBasePath}/${appointment.business.slug}`
																		)
																	}
																/>
															</div>
														)}
													</div>
												) : isPhoneCode(shortCode) ? (
													<form className={styles.form} onSubmit={this.verify}>
														<Card
															className={styles.card}
															hasTitleInside
															isVisibleMobile={false}
															title={t('manageAppointment.title')}
														>
															<p>
																<Localize text={'manageAppointment.alert'} />
															</p>
															<div className={styles.input}>
																<PhoneInput
																	defaultCountry={countryCode}
																	label={t(
																		'manageAppointment.placeholders.phoneNumber'
																	)}
																	localizedPlaceholder={t(
																		'manageAppointment.placeholders.phoneNumber'
																	)}
																	value={phoneNumber}
																	onChange={this.setPhoneNumber}
																/>
															</div>
															<Button
																className={styles.mobile}
																isDisabled={!!isPending}
																isFullWidth
																label={t(
																	`actions.${
																		isPending ? 'submitting' : 'submit'
																	}`
																)}
																size='mobile'
																type={'primary'}
															/>
															<Button
																className={styles.tablet}
																isDisabled={!!isPending}
																label={t(
																	`actions.${
																		isPending ? 'submitting' : 'submit'
																	}`
																)}
																type={'primary'}
															/>
														</Card>
													</form>
												) : null}
											</div>
										</Container>
									</div>
								</Layout>
							);
						}

						setPhoneNumber = (phoneNumber, isValid) => {
							this.setState(
								({ error, phoneNumber: phoneNumberWas }) => ({
									phoneNumber,
									hasInvalidPhoneNumber: !isValid,
									error:
										error === MISSING_NUMBER_ERROR && (phoneNumber || !isValid)
											? null
											: phoneNumber !== phoneNumberWas &&
											  error === INVALID_CODE_NUMBER_PAIR_ERROR
											? null
											: isValid && error === INVALID_NUMBER_ERROR
											? null
											: error
								}),
								() => {
									scrollTo({ top: 0 });
								}
							);
						};
						verify = (e, silentError) => {
							// silentError is used when a user is already logged in.
							// We secretly call the lambda with the shortcode and the user's phone number.
							// If it's not the phone number linked to the appointment, we do not want to show the error.
							// We only want to show the error when the user enter a wrong phone number
							if (e && e.preventDefault) {
								e.preventDefault();
							}
							const { phoneNumber, error, status } = this.state;
							const { match } = this.props;
							if ((!error || error === NETWORK_ERROR) && status !== 'pending') {
								const { shortCode } = match.params;
								if (phoneNumber || !isPhoneCode(shortCode)) {
									this.setState(
										{
											status: 'pending',
											error: null
										},
										() => {
											invokeLambda('verifyAppointmentCode', {
												phoneNumber,
												shortCode
											})
												.then(
													async ({
														business,
														appointment,
														customerId,
														errorMessage
													}) => {
														if (!appointment) {
															throw errorMessage || new Error();
														}
														const appointmentId = Object.keys(
															appointment?.sequence || {}
														).join(',');

														const {
															[appointmentId]: appointmentWithOnlinePaymentData
														} = await withOnlinePaymentData({
															appointments: { [appointmentId]: appointment },
															allStripeFees: this.props.allStripeFees,
															businesses: { [appointment.businessId]: business }
														});
														this.setState(
															errorMessage
																? {
																		error: errorMessage,
																		status: 'error'
																  }
																: {
																		status: 'success',
																		appointment: {
																			...appointment,
																			...appointmentWithOnlinePaymentData,
																			business,
																			smsReminderCustomerId: customerId
																		},
																		business
																  }
														);
													}
												)
												.catch(error => {
													if (
														error === 'INVALID_CODE_NUMBER_PAIR_ERROR' &&
														this?.props?.user?.phone &&
														silentError
													) {
														this.setState({
															status: initialStatus(this.props),
															phoneNumber: '',
															error: null
														});
													} else {
														this.setState({
															status: 'error',
															error:
																error && error.name === 'TypeError'
																	? NETWORK_ERROR
																	: error
														});
													}
												});
										}
									);
								} else {
									this.setState(({ hasInvalidPhoneNumber }) => ({
										error: silentError
											? null
											: hasInvalidPhoneNumber
											? INVALID_NUMBER_ERROR
											: MISSING_NUMBER_ERROR
									}));
								}
							}
						};
					}
				)
			)
		)
	)
);

function isPhoneCode(code) {
	return !!(code && code.length === 3);
}

function initialStatus({ match }) {
	const { shortCode } = match.params;
	return isPhoneCode(shortCode) ? 'waitingPhone' : 'waitingVerification';
}
