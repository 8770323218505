import { capitalize, safeRead } from '@planity/helpers';

// This is the adaptation of the search.js file
// https://github.com/planity/next/blob/5adde22a9b58b05cf1fa885d2afb8f63e3f07438/packages/translations/fr/search.js

export function formatPlaceTitle(
	{ leafPlace, googlePlace, userLocation, searchPlace },
	t
) {
	if (searchPlace) {
		if (!leafPlace && !googlePlace)
			return (safeRead(searchPlace, ['name']) || '').replace(
				'Arrondissement',
				'arrondissement'
			);

		if (googlePlace) {
			// Could be better... But could be worse !
			return ` - ${googlePlace.name}`;
		}
		const placeName = safeRead(leafPlace, ['name'] || '').replace(
			'Arrondissement',
			'arrondissement'
		);

		if (leafPlace.preposition) return `${leafPlace.preposition} ${placeName}`;

		// ¯\_(ツ)_/¯
		return t('search.title.place.at', { placeName });
	}

	if (userLocation) return t('search.title.place.aroundMe');
	return t('search.title.place.default');
}

export function formatCategoryTitle(
	searchCategory,
	t,
	language,
	isGoogleSearch
) {
	// Les meilleurs salons et instituts
	if (!searchCategory) {
		return t('search.title.category.default');
	}

	const { isFeminine, name, plural, isBrand } = searchCategory;
	// Les établissements ${name}
	if (isBrand) return t('search.title.category.brand', { name });

	if (isGoogleSearch) return capitalize(plural || name);

	const isDE = ['de'].includes(language);

	const categoryName = isDE
		? capitalize(plural || name)
		: (plural || name).toLowerCase();

	// Les meilleures ${name}
	// For DE, plural is always feminine
	if (isFeminine || isDE)
		return t('search.title.category.feminine', { name: categoryName });

	// Les meilleurs ${name}
	return t('search.title.category.masculine', { name: categoryName });
}

export function formatCategorySubTitle({ categorySearch, t, language }) {
	const { isFeminine, name, plural, isBrand } = categorySearch;

	if (isBrand) return t('search.title.category.default');

	const isDE = ['de'].includes(language);

	const categoryName = isDE
		? capitalize(plural || name)
		: (plural || name).toLowerCase();

	// Les meilleures ${name}
	// Nagestudio is feminine in DE but in this case masculine works too. And as we prefer all categories with the same subtitle, we choose masculine key for all.
	if (isFeminine && !isDE)
		return t('search.title.category.feminine', { name: categoryName });

	// Les meilleurs ${name}
	return t('search.title.category.masculine', { name: categoryName });
}

export const MIN_DURATION_SPINNER_DISPLAY = 1000;
export const MAX_MEMOIZED_BUSINESSES_COUNT = 500;
export const USE_MEMOIZED_BUSINESS = true;
export const DEFAULT_MAP_ZOOM = 12;
