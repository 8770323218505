import pako from 'pako/lib/inflate';
import {
	businessService,
	fulfillSequence,
	legacyFormatAvailabilities,
	reduce,
	getDelaysConfig
} from '@planity/helpers';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { groupBy } from 'lodash';

const DEFAULT_SLOT_VALIDITY = 30;

export const getShardName = businessId => {
	const sum = [...businessId]
		.map(c => c.charCodeAt(0))
		.reduce((a, x) => a + x, 0);
	const shard = (sum % 2) + 1;
	return `availabilities${shard}`;
};

export const getServices = steps => {
	return Array.from(
		steps.reduce((services, { serviceId }) => {
			services.add(serviceId);
			return services;
		}, new Set())
	);
};

export const parseAvailabilities = data => {
	if (data) {
		try {
			const str = pako.inflate(data, { to: 'string' });
			return JSON.parse(str);
		} catch (e) {
			if (process.env.NODE_ENV === 'development') {
				console.warn(e);
			}
			return null;
		}
	} else {
		return null;
	}
};

// withavailabilities helpers
export const formatAvailabilities = ({
	steps,
	availabilities: rawAvailabilities,
	today,
	business
}) => {
	const availabilities = legacyFormatAvailabilities(
		rawAvailabilities,
		business
	);
	const { serviceId } = steps[0];
	const firstStepAvailabilities = availabilities[serviceId];
	const sequence = stepsDescription(steps, business);

	const delaysConfig = getDelaysConfig(steps, business);

	const latestDelayToAvailability = addMinutes(today, delaysConfig.latest || 0);
	const soonestDelayToAvailability = addMinutes(today, delaysConfig.soonest);

	return reduce(
		firstStepAvailabilities || {},
		(sequenceAvailabilities, hours, day) => {
			const formattedAvailabilities = reduce(
				hours,
				(dayAvailabilities, hourAvailabilities, hour) => {
					// exclude stale availabilities
					let cursor = parseISO(`${day} ${hour}`);

					if (
						!isBefore(cursor, latestDelayToAvailability) &&
						isBefore(cursor, soonestDelayToAvailability)
					) {
						const hourSequence = sequence.reduce(
							(seq, step) => {
								seq.sequence.push({
									...step,
									start: seq.cursor
								});

								seq.cursor = addMinutes(seq.cursor, step.duration);
								return seq;
							},
							{ cursor: parseISO(`${day} ${hour}`), sequence: [] }
						).sequence;
						if (
							fulfillSequence({
								sequence: hourSequence,
								availabilities,
								business
							})
						) {
							dayAvailabilities.push({ hour });
						}
					}
					return dayAvailabilities;
				},
				[]
			);
			if (formattedAvailabilities.length) {
				sequenceAvailabilities[day] = formattedAvailabilities;
			}
			return sequenceAvailabilities;
		},
		{}
	);
};

export const withStaleAvailabilities = (are, were) => {
	const daysWere = Object.keys(were || {});
	const daysAre = Object.keys(are || {});
	const { staleDays = [], remainingDays = [] } = groupBy(daysWere, day =>
		daysAre.includes(day) ? 'remainingDays' : 'staleDays'
	);
	return staleDays.reduce(
		(availabilities, day) => {
			if (!availabilities[day]) {
				availabilities[day] = were[day].map(hour => ({
					...hour,
					isStale: true
				}));
			}
			return availabilities;
		},
		remainingDays.reduce(
			(availabilities, day) => {
				were[day].forEach(({ hour }) => {
					if (!availabilities[day].find(h => h.hour === hour)) {
						availabilities[day] = [
							...availabilities[day],
							{ hour, isStale: true }
						].sort((x, y) => (x.hour < y.hour ? -1 : 1));
					}
				});
				return availabilities;
			},
			{ ...are }
		)
	);
};

function sequenceDescription(sequence) {
	if (!sequence) {
		return null;
	}

	return sequence.reduce((calendars, step) => {
		if (step.sequence) {
			return calendars.concat(sequenceDescription(step.sequence));
		}
		const hasCalendar =
			step.calendarId && (step.calendarId || []).filter(x => !!x).length;
		calendars.push(hasCalendar ? [step.calendarId] : null);
		return calendars;
	}, []);
}

export function stepsDescription(steps, business) {
	return steps.reduce((sequence, step) => {
		const service = businessService(business, step.serviceId);
		const sequenceCalendars = sequenceDescription(step.sequence);
		const calendarIds = Array.isArray(step.calendarId)
			? [
					step.calendarId.map(_calendarId =>
						_calendarId === 'ANY' ? null : _calendarId
					)
			  ]
			: null;
		const slotValidity =
			service.interval ||
			business.settings?.timeslots?.intervals ||
			DEFAULT_SLOT_VALIDITY;

		sequence.push({
			duration: service.duration,
			serviceId: step.serviceId,
			sequence:
				sequenceCalendars && sequenceCalendars.filter(x => !!x).length
					? sequenceCalendars
					: null,
			isComplex: !!step.sequence,
			calendarIds,
			slotValidity
		});
		return sequence;
	}, []);
}
