import Switch from 'react-switch';
import React, { useEffect, useMemo, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './waiting_list.module.scss';
import { useTranslation } from '@planity/localization';
import colors from '@planity/theme/colors';
import {
	checkWaitingListCancellationDelay,
	useWaitingList,
	getWaitingListIneligibleServices,
	userEnabledWaitingList,
	userDisabledWaitingList,
	proDisabledWaitingList,
	getDelaysConfig
} from '@planity/helpers';
import { getUserId, useAuth, useErrorMessages } from '@planity/components';

export function WaitingList({
	appointment,
	appointmentId,
	appointmentStart,
	fees,
	finalPaidAmount,
	paymentCreatedAt,
	canBeCancelled
}) {
	const { isPro } = useAuth();

	useStyles(styles);
	const classes = classNames.bind(styles);
	const { t } = useTranslation();

	const { pushMessage } = useErrorMessages();
	const [hasWaitingListActivated, setHasWaitingListActivated] = useState(
		!!appointment.hasWaitingListActivated
	);

	const business = appointment.business;
	const isWaitingListSkippedByPro = business?.settings?.skipWaitingList;
	const userId = getUserId();
	const smsReminderCustomerId = appointment?.smsReminderCustomerId;

	const {
		waitingListError,
		addWaitingListAppointment,
		deleteWaitingListAppointment
	} = useWaitingList(appointmentId);

	const lateCancellationDelay = useMemo(
		() =>
			(Object.values(appointment?.sequence || {})[0]?.lateCancellationDelay ||
				0) * 60,
		[appointment]
	);

	const canSwitchWaitingList = useMemo(() => {
		const { cancellation } = getDelaysConfig(
			Object.values(appointment?.sequence),
			business
		);
		return checkWaitingListCancellationDelay({
			appointmentStart,
			businessCancellationDelay: cancellation,
			veventLateCancellationDelay: !!appointment.paymentMethod
				? lateCancellationDelay
				: 0 // lateCancellationDelay represented in hours, so multiply by 60
		});
	}, [business, appointmentStart, lateCancellationDelay, appointment]);

	const switchChangeHandler = async isWaitingListActivated => {
		setHasWaitingListActivated(isWaitingListActivated);
		if (isWaitingListActivated) {
			userEnabledWaitingList();
			await addWaitingListAppointment({
				businessId: appointment.businessId,
				userPaysFeesAmount: fees,
				appointmentTotalAmount: finalPaidAmount,
				paymentCreatedAt
			});
		} else {
			isPro ? proDisabledWaitingList() : userDisabledWaitingList();
			await deleteWaitingListAppointment();
		}
	};

	const waitingListIneligibleServices =
		getWaitingListIneligibleServices(business);
	const isIllegitimateSequence = (appointment.sequence || []).some(
		({ serviceId, serviceOrigin }) =>
			!serviceId ||
			waitingListIneligibleServices.includes(serviceOrigin || serviceId)
	);

	const activatedThenPassedToIllegitimate =
		isIllegitimateSequence && hasWaitingListActivated;

	const isFromSmsReminder = !!smsReminderCustomerId;

	const isLoggedUserAppointment = isFromSmsReminder
		? smsReminderCustomerId === userId
		: true;
	/**
	 * Display the waiting list switch for the following conditions
	 *
	 * 1. !isWaitingListSkippedByPro is not activated for the business
	 * 2. canBeCancelled -> can move an appointment because appointment is in the future
	 * 3.
	 *   a) canSwitchWaitingList -> Doesn't pass the cancellation delay (OR)
	 *   b) !canSwitchWaitingList && hasWaitingListActivated -> Passed the cancellation delay but has waitingList activated (display and disable switch)
	 * 4. !isIllegitimateSequence (deleted, not bookable, hidden, forbidden services)
	 * 5. isIllegitimateSequence && activatedThenPassedToIllegitimate  (passed to isIllegitimateSequence after waitingList activation)
	 * 6. isLoggedUserAppointment -> The logged user is the one who booked the appointment
	 * 7. the appointment was booked by the current user
	 *
	 */
	const displayWaitingListSwitch =
		!isWaitingListSkippedByPro &&
		canBeCancelled &&
		(canSwitchWaitingList ||
			(!canSwitchWaitingList && hasWaitingListActivated)) &&
		(!isIllegitimateSequence || activatedThenPassedToIllegitimate) &&
		isLoggedUserAppointment &&
		!appointment.bookedBy;

	useEffect(() => {
		if (waitingListError) {
			setHasWaitingListActivated(appointment.hasWaitingListActivated);
			pushMessage({
				message: 'myAccount.appointment.errors.WAITING_LIST_ERROR',
				type: 'error'
			});
		}
	}, [waitingListError, appointment.hasWaitingListActivated]);

	if (!displayWaitingListSwitch) return null;

	const isActiveDisabled =
		!canSwitchWaitingList || activatedThenPassedToIllegitimate;

	return (
		<div
			className={classes({
				waitingListContainer: true,
				activeDisabled: isActiveDisabled,
				active: !isActiveDisabled && hasWaitingListActivated,
				inactive: !isActiveDisabled && !hasWaitingListActivated
			})}
		>
			<div
				className={classes({
					waitingListWrapper: true
				})}
			>
				<Switch
					checked={hasWaitingListActivated}
					onChange={switchChangeHandler}
					onColor={colors.black.background}
					offColor={colors.grey.grey200}
					disabled={isActiveDisabled}
					uncheckedIcon={false}
					checkedIcon={false}
					height={24}
					width={44}
					activeBoxShadow={''}
					onHandleColor={colors.white}
					offHandleColor={colors.white}
					handleDiameter={16}
				/>
				<span
					className={classes({
						message: true
					})}
				>
					{activatedThenPassedToIllegitimate
						? t('appointment.waitingList.invalid2')
						: canSwitchWaitingList
						? t('appointment.waitingList.valid')
						: t('appointment.waitingList.invalid')}
				</span>
			</div>
		</div>
	);
}
