//TODO TO BE REMOVED PLAN-17265

import { useLocalizedRoutes } from '@planity/localization';
import { BottomLinks } from '../ui';
import styles from './places.module.scss';
import React from 'react';

export function TemporaryTownsBoost({ category }) {
	const { routes } = useLocalizedRoutes();

	const Bonn = {
		name: 'Bonn',
		slug: 'bonn',
		preposition: 'in',
		postalCodes: [
			'53111',
			'53113',
			'53115',
			'53117',
			'53119',
			'53121',
			'53123',
			'53125',
			'53127',
			'53129',
			'53173',
			'53175',
			'53177',
			'53179',
			'53225',
			'53227',
			'53229'
		],
		_geoloc: {
			lng: 7.1002,
			lat: 50.7362
		},
		population: 327258,
		type: 'locality',
		depth: 2,
		parentId: '1569e51e-1342-4df7-b173-08f24e45f94f',
		countryCode: 'DE',
		importedAt: 1647955177513,
		coverUrl:
			'https://res.cloudinary.com/planity/image/upload/v1648459828/portail/bonn.jpg',
		objectID: 'd356b1b3-7d52-436d-9c60-c12e05be12fe'
	};
	const Mainz = {
		name: 'Mainz',
		slug: 'mainz',
		preposition: 'in',
		postalCodes: [
			'55116',
			'55118',
			'55120',
			'55122',
			'55124',
			'55126',
			'55127',
			'55128',
			'55129',
			'55130',
			'55131'
		],
		_geoloc: {
			lng: 8.2688,
			lat: 50.001
		},
		population: 217118,
		type: 'locality',
		depth: 2,
		parentId: '40c7e04e-dc55-4814-bd80-22f3c6aa201c',
		countryCode: 'DE',
		importedAt: 1647955177513,
		coverUrl:
			'https://res.cloudinary.com/planity/image/upload/v1648459828/portail/mainz.jpg',
		objectID: 'a645a716-acef-4ac1-932b-b055f9e53e83'
	};
	const Oldenburg = {
		name: 'Oldenburg',
		slug: 'oldenburg',
		preposition: 'in',
		postalCodes: [
			'26121',
			'26122',
			'26123',
			'26125',
			'26127',
			'26129',
			'26131',
			'26133',
			'26135'
		],
		_geoloc: {
			lng: 8.205,
			lat: 53.1599
		},
		population: 168210,
		type: 'locality',
		depth: 2,
		parentId: '2a85c88d-e9cd-41b3-a3b1-8b9ab27dcd50',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: 'e24c84df-efa2-4b4f-88b3-b3d9c310444b',
		districtIds: [],
		nearbyIds: []
	};
	const Koblenz = {
		name: 'Koblenz',
		slug: 'koblenz',
		preposition: 'in',
		postalCodes: [
			'56068',
			'56070',
			'56072',
			'56073',
			'56075',
			'56076',
			'56077'
		],
		_geoloc: {
			lng: 7.5932,
			lat: 50.3567
		},
		population: 114024,
		type: 'locality',
		depth: 2,
		parentId: '712af572-0fed-42a1-894c-466d6fb30f4f',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: '185cec19-621b-4ac6-9be6-3cc2aacc2e07'
	};
	const Monchengladbach = {
		name: 'Mönchengladbach',
		slug: 'monchengladbach',
		preposition: 'in',
		postalCodes: [
			'41061',
			'41063',
			'41065',
			'41066',
			'41068',
			'41069',
			'41169',
			'41179',
			'41189',
			'41199',
			'41236',
			'41238',
			'41239'
		],
		_geoloc: {
			lng: 6.4387,
			lat: 51.1958
		},
		population: 261454,
		type: 'locality',
		depth: 2,
		parentId: '9228d7c2-6be8-4817-a5ca-14dec00d0bc4',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: '0bbc3a0d-bd0a-46f3-87fd-d9b8b1147598'
	};
	const Wiesbaden = {
		name: 'Wiesbaden',
		slug: 'wiesbaden',
		preposition: 'in',
		postalCodes: [
			'65183',
			'65185',
			'65187',
			'65189',
			'65191',
			'65193',
			'65195',
			'65197',
			'65199',
			'65201',
			'65203',
			'65205',
			'65207'
		],
		_geoloc: {
			lng: 8.2389,
			lat: 50.0817
		},
		population: 278342,
		type: 'locality',
		depth: 2,
		parentId: '4874d828-0736-4237-a715-22dba8638d86',
		countryCode: 'DE',
		importedAt: 1647955177513,
		coverUrl:
			'https://res.cloudinary.com/planity/image/upload/v1648459829/portail/wiesbaden.jpg',
		objectID: '3bb9231e-b2f0-4866-9b74-e698c3de5dd5'
	};
	const Krefeld = {
		name: 'Krefeld',
		slug: 'krefeld',
		preposition: 'in',
		postalCodes: [
			'47798',
			'47799',
			'47800',
			'47802',
			'47803',
			'47804',
			'47805',
			'47807',
			'47809',
			'47829',
			'47839'
		],
		_geoloc: {
			lng: 6.5616,
			lat: 51.3311
		},
		population: 227020,
		type: 'locality',
		depth: 2,
		parentId: '62088474-3f8c-4349-84c9-afba3b9c724c',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: '3e9f2a55-e211-45bc-a6b8-899d4ea12210',
		districtIds: [],
		nearbyIds: []
	};
	const Ingolstadt = {
		name: 'Ingolstadt',
		slug: 'ingolstadt',
		preposition: 'in',
		postalCodes: ['85049', '85051', '85053', '85055', '85057'],
		_geoloc: {
			lng: 11.4147,
			lat: 48.7667
		},
		population: 136981,
		type: 'locality',
		depth: 2,
		parentId: '7c30e51a-0651-4bf0-bbcb-cda1222326af',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: '56a4de17-d027-4e52-994d-26d06b33e2ab'
	};
	const Dortmund = {
		name: 'Dortmund',
		slug: 'dortmund',
		preposition: 'in',
		postalCodes: [
			'44135',
			'44137',
			'44139',
			'44141',
			'44143',
			'44145',
			'44147',
			'44149',
			'44225',
			'44227',
			'44229',
			'44263',
			'44265',
			'44267',
			'44269',
			'44287',
			'44289',
			'44309',
			'44319',
			'44328',
			'44329',
			'44339',
			'44357',
			'44359',
			'44369',
			'44379',
			'44388'
		],
		_geoloc: {
			lng: 7.477,
			lat: 51.5125
		},
		population: 587010,
		type: 'locality',
		depth: 2,
		parentId: '3b49b9a5-e906-43ae-a9e1-3f16f4f3a71f',
		countryCode: 'DE',
		importedAt: 1647955177513,
		coverUrl:
			'https://res.cloudinary.com/planity/image/upload/v1675103141/portail/Dortmund.jpg',
		objectID: '583b9f2b-a161-43a8-9f8f-f455f34b9d21'
	};
	const Wuppertal = {
		name: 'Wuppertal',
		slug: 'wuppertal',
		preposition: 'in',
		postalCodes: [
			'42103',
			'42105',
			'42107',
			'42109',
			'42111',
			'42113',
			'42115',
			'42117',
			'42119',
			'42275',
			'42277',
			'42279',
			'42281',
			'42283',
			'42285',
			'42287',
			'42289',
			'42327',
			'42329',
			'42349',
			'42369',
			'42389',
			'42399'
		],
		_geoloc: {
			lng: 7.1505,
			lat: 51.2569
		},
		population: 354382,
		type: 'locality',
		depth: 2,
		parentId: 'fd2e448a-3b0a-4ca7-82da-c70250e46ef8',
		countryCode: 'DE',
		importedAt: 1647955177513,
		objectID: '30e26c4d-ff42-40d7-a198-2c0a006c62bc'
	};

	const featured = {
		friseur: [
			Bonn,
			Mainz,
			Oldenburg,
			Koblenz,
			Monchengladbach,
			Wiesbaden,
			Krefeld,
			Ingolstadt
		],
		kosmetikstudio: [
			Dortmund,
			Oldenburg,
			Wiesbaden,
			Mainz,
			Wuppertal,
			Bonn,
			Monchengladbach,
			Koblenz,
			Krefeld
		]
	};

	if (!Object.keys(featured).includes(category.slug)) return null;

	return (
		<BottomLinks
			className={styles.featuredTowns}
			getPath={parentPlace =>
				routes.catchAll({
					search: {
						parentCategory: category,
						parentPlace
					}
				})
			}
			getText={label => label?.name || ''}
			links={featured[category.slug]}
			title={`Entdecken Sie ${category.slug} in Ihrer Nähe`}
		/>
	);
}
