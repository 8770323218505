import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import credentials from '@planity/credentials';
import styles from './redirect_to_country_modal.module.scss';
import useStyles from 'isomorphic-style-loader/useStyles';
import { DEFAULT_LOCALE, useTranslation } from '@planity/localization';
import { Button, Flag, Radio, useModal } from '@planity/ui';

export const RedirectToCountryModalContent = ({ suggestedCountry, onSave }) => {
	// used for redirection
	const [selectedLocale, setSelectedLocale] = useState(null);

	const classes = classNames.bind(styles);
	useStyles(styles);

	const { t } = useTranslation();
	const { closeModal } = useModal();

	const suggestedCountries = useMemo(() => {
		switch (suggestedCountry) {
			case 'BE':
				return [
					{
						countryCode: 'BE',
						language: 'fr',
						locale: 'fr-BE',
						name: 'belgium'
					},
					{
						countryCode: 'FR',
						language: 'fr',
						locale: 'fr-FR',
						name: 'france'
					}
				];
			case 'DE':
				return [
					{
						countryCode: 'DE',
						language: 'de',
						locale: 'de-DE',
						name: 'germany'
					},
					{
						countryCode: 'FR',
						language: 'fr',
						locale: 'fr-FR',
						name: 'france'
					}
				];
			default:
				console.error(
					'Error in RedirectToCountryModalContent. Suggesting default countries, this is not normal.'
				);
				return [
					{ countryCode: 'FR', language: 'fr', locale: 'fr-FR', name: 'france' }
				];
		}
	}, [suggestedCountry]);
	// used for translation
	const lng =
		selectedLocale?.split('-')?.at(0) || suggestedCountries?.at(0)?.language;

	const onSubmit = useCallback(() => {
		onSave();
		if (!selectedLocale || selectedLocale === DEFAULT_LOCALE) return;
		window.location.href =
			process.env.NODE_ENV === 'development'
				? `http://localhost:3000/${selectedLocale}`
				: `https://www.${credentials.HOST}/${selectedLocale}`;
	}, [selectedLocale, closeModal, onSave]);

	return (
		<div className={classes({ redirectToCountryModal: true })}>
			<h1>{t('localization.selectCountry.title', { lng })}</h1>
			<p>{t('localization.selectCountry.description', { lng })}</p>
			<div className={styles.items}>
				{suggestedCountries.map(({ countryCode, language, locale, name }) => (
					<div
						key={locale}
						className={styles.item}
						onClick={() => setSelectedLocale(locale)}
					>
						<Radio
							id={'country-modal-suggested-radio'}
							isChecked={selectedLocale === locale}
							label={t(`localization.country.${name}`, { lng: language })}
							name={'country'}
						/>
						<Flag country={countryCode} />
					</div>
				))}
			</div>
			<Button
				id={'country-modal-submit-button'}
				isFullWidth
				label={t(`localization.selectCountry.validate`, { lng })}
				size={'mobile'}
				type={'primary'}
				onClick={onSubmit}
			/>
		</div>
	);
};
