import React, { useState, useCallback } from 'react';
import { useLocalization, useTranslation } from '@planity/localization';
import { Button, Flag, Radio, Icon, useModal } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './render_country_selector_modal.module.scss';
import { sendToUserApp } from '@planity/webview';

const SELECTABLE_LANGUAGE = [
	{
		label: 'french',
		flag: 'FR',
		countryCode: 'FR',
		language: 'fr',
		locale: 'fr-FR'
	},
	{
		label: 'belgium',
		flag: 'BE',
		countryCode: 'BE',
		language: 'fr',
		locale: 'fr-BE'
	},
	{
		label: 'german',
		flag: 'DE',
		countryCode: 'DE',
		language: 'de',
		locale: 'de-DE'
	}
];

export function RenderCountrySelectorModal() {
	useStyles(styles);
	const classes = classNames.bind(styles);
	const { t } = useTranslation();
	const { closeModal } = useModal();
	const { locale, language, countryCode } = useLocalization();
	const [localeInfo, setLocaleInfo] = useState({
		countryCode,
		language,
		locale
	});

	const onSubmit = useCallback(() => {
		sendToUserApp({
			type: 'SET_USER_COUNTRY_CODE',
			payload: {
				countryCode: localeInfo.countryCode,
				language: localeInfo.language
			}
		});
		closeModal();
	}, [closeModal, localeInfo]);

	return (
		<div className={classes({ contentSelectCountry: true })}>
			<div className={classes({ information: true })}>
				<Icon icon='Information' size={'medium'} />
				<p>
					{t('localization.modal.information', { lng: localeInfo.language })}
				</p>
			</div>
			<div className={classes({ contentFlagsContainer: true })}>
				{SELECTABLE_LANGUAGE.map(
					({ countryCode, flag, label, language, locale }, key) => (
						<div
							key={key}
							className={classes({ contentFlags: true })}
							onClick={() => setLocaleInfo({ countryCode, language, locale })}
						>
							<Radio
								id={'country-modal-selected'}
								isChecked={localeInfo.locale === locale}
								label={t(`localization.modal.languages.${label}`)}
								name={'country'}
							/>
							<Flag className={styles.flag} country={flag} />
						</div>
					)
				)}
			</div>
			<Button
				id={'select-country-button'}
				label={t('localization.selectCountry.validate', {
					lng: localeInfo.language
				})}
				isFullWidth
				size={'mobile'}
				onClick={onSubmit}
			/>
		</div>
	);
}
