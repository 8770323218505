import React from 'react';
import { format, parseISO } from 'date-fns';
import { useLocalization } from '@planity/localization';
import capitalize from 'lodash/capitalize';
import { Localize } from '@planity/components';
import { Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './close_availabilities.module.scss';

/**
 * Given a language, returns a format for dates on search page
 * @param language {string} the website's language
 * @return {string} the format string
 */
function dateFormat(language) {
	switch (language) {
		case 'fr':
		case 'de':
		default:
			return 'EEEdd'; // Lun.12
	}
}

export const CloseAvailabilities = ({ nextAvailabilities, isSearch }) => {
	const classes = classNames.bind(styles)({
		closeAvailabilities: true
	});
	const { dateLocale, language } = useLocalization();
	useStyles(styles);
	return (
		<div className={`${classes} ${styles.container}`}>
			<div className={styles.legend}>
				<div className={styles.day}>
					<div className={`${styles.halfDay} ${styles.title}`}>
						<Localize text={'business.nextMorningAvailabilities'} />
					</div>
					<div className={`${styles.halfDay} ${styles.title}`}>
						<Localize text={'business.nextAfternoonAvailabilities'} />
					</div>
				</div>
			</div>
			{Object.entries(nextAvailabilities).map(([date, halfDays], index) => {
				const formattedDay = capitalize(
					format(parseISO(date), dateFormat(language), { locale: dateLocale })
				);
				return (
					<div className={styles[`dispo-${index + 1}`]} key={index}>
						<div className={`${styles.day} ${styles.dispos}`}>
							{halfDays.map((isAvailable, index) => (
								<Button
									hasAvailabilities={isSearch}
									isDisabled={!isAvailable}
									key={index}
									label={formattedDay}
									size={'small'}
									type='tertiary'
								/>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};
