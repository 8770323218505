import { computeFees, formatPrice } from './price';

export function totalAppointmentPrice(
	onlinePaymentServices,
	includeFees,
	stripeFees = {},
	serviceIdsWithEligibleCures = []
) {
	const AMOUNT = 'amount';

	// Get max value of all online payment services' deposit rate
	// In Belgium, the deposit can be a fixed amount instead of a percentage,
	// so these cases are excluded from the calculation
	const maxPercentDepositRate = Math.max.apply(
		null,
		onlinePaymentServices.isOnline.map(
			service =>
				(service.onlinePayment &&
					(service.depositMode ? service.depositMode !== AMOUNT : true) &&
					service.cancelRate) ||
				0
		)
	);
	const { totalPrice, depositPrice, totalPriceServicesWithEligibleCures } =
		onlinePaymentServices.isOnline.reduce(
			(acc, service) => {
				const { depositMode, prices, id, onlinePayment, cancelRate } = service;
				if (!prices) return acc;

				const price = prices.default || prices.min || prices.max || 0;
				const isEligible = serviceIdsWithEligibleCures.includes(id);

				if (isEligible) {
					acc.totalPriceServicesWithEligibleCures += price;
				}

				acc.depositPrice +=
					onlinePayment && depositMode === AMOUNT
						? cancelRate
						: (price * maxPercentDepositRate) / 100;

				acc.totalPrice += price;

				return acc;
			},
			{
				totalPrice: 0,
				depositPrice: 0,
				totalPriceServicesWithEligibleCures: 0
			}
		);

	if (includeFees) {
		return {
			totalPriceWithEligibleCures: formatPrice(
				totalPrice -
					totalPriceServicesWithEligibleCures +
					computeFees({
						amount: totalPrice - totalPriceServicesWithEligibleCures,
						stripeFees
					}),
				true
			),
			totalPrice: formatPrice(
				totalPrice +
					computeFees({
						amount: totalPrice,
						stripeFees
					}),
				true
			),
			totalPriceWithoutFees: Math.round(totalPrice),
			depositPrice: formatPrice(
				depositPrice +
					computeFees({
						amount: depositPrice,
						stripeFees
					}),
				true
			),
			depositPriceWithoutFees: Math.round(depositPrice),
			depositRest: formatPrice(totalPrice - depositPrice, true)
		};
	}

	return {
		totalPriceWithEligibleCures: formatPrice(
			totalPrice - totalPriceServicesWithEligibleCures,
			true
		),
		totalPrice: formatPrice(totalPrice, true),
		depositPrice: formatPrice(depositPrice, true),
		depositRest: formatPrice(totalPrice - depositPrice, true),
		totalPriceWithoutFees: Math.round(totalPrice),
		depositPriceWithoutFees: Math.round(depositPrice)
	};
}
