import { useUserQueries, BEAUTY_TEAM_NUMBER } from '@planity/components';
import { noop } from '@planity/helpers';
import {
	useLocalizedRoutes,
	useLocalization,
	getLocalizedBasePath,
	DEFAULT_COUNTRY_CODE,
	getBusinessWebsiteCountryCode
} from '@planity/localization';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { Icon, Avatar, useModal } from '@planity/ui';
import { useHistory } from 'react-router-dom';
import {
	CountryCodeAlertModalContent,
	CountryCodeAlertModalButtons
} from '../../country_code_alert_modal';
import styles from './business_preview.module.scss';
import { isNativeApp, sendToUserApp } from '@planity/webview';

// TODO Rename this prop and rewrite the CSS associated with it
export function BusinessPreview({
	fromSearchBusinesses = false,
	onBusinessSelect = noop,
	Title = null
}) {
	useStyles(styles);
	const { beautyTeam } = useUserQueries();
	const cx = classNames.bind(styles);
	const classes = cx({
		businessPreview: true,
		propositionOne: beautyTeam?.length === 1,
		fromSearchBusinesses,
		hasBeautyTeam: (beautyTeam || []).length > 0
	});
	const { routes } = useLocalizedRoutes();
	const history = useHistory();
	const { setModal } = useModal();
	const { language, countryCode } = useLocalization();

	const linkToBusiness = business => {
		onBusinessSelect();
		history.push(
			routes.catchAll({
				business: business,
				state: { business }
			})
		);
	};
	const redirectToBusinessLocale = business => {
		const localePath = getLocalizedBasePath({
			language: business.language,
			countryCode: business.countryCode
		});
		const validLocalePath =
			localePath === 'fr-FR'
				? ''
				: `/${localePath === 'nl-BE' ? 'fr-BE' : localePath}`;

		const newUrl = `${window.location.origin}${validLocalePath}/${business.slug}`;
		// Side effect should not happen in render. But this one seems safe
		if (isNativeApp)
			return sendToUserApp({
				type: '300_REDIRECTION',
				payload: {
					to: newUrl
				}
			});
		window.location.href = newUrl;
	};
	const handleClick = business => {
		const shouldSuggestRedirection =
			countryCode !== getBusinessWebsiteCountryCode(business);
		if (shouldSuggestRedirection) {
			setModal({
				content: (
					<CountryCodeAlertModalContent
						businessCountryCode={business.countryCode || DEFAULT_COUNTRY_CODE}
						localeCountryCode={countryCode}
					/>
				),
				footerButtons: (
					<CountryCodeAlertModalButtons
						onCancelClick={noop}
						onSubmitClick={() => redirectToBusinessLocale(business)}
					/>
				),
				hasCloseBtn: false
			});

			return;
		}

		linkToBusiness(business);
	};
	return (
		<div className={classes} data-hasbeautyteam={(beautyTeam || []).length > 0}>
			{!!beautyTeam?.length && (
				<>
					{Title}

					<div className={styles.propositions}>
						{beautyTeam.slice(0, BEAUTY_TEAM_NUMBER).map((business, i) => (
							<div
								key={i}
								className={styles.proposition}
								onClick={() => handleClick(business)}
							>
								<div className={styles.propositionImage}>
									<Avatar
										imageUrl={business?.pictures?.at(0)}
										size='large'
										shape='square'
										isCloudinary
										icon={!business?.pictures?.at(0) && 'Store'}
										sizeIcon={'32'}
									/>
								</div>
								<span className={styles.propositionTitle}>{business.name}</span>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}
