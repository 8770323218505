import { useLocalization, useLocalizedRoutes } from '@planity/localization';
import { isNativeApp } from '@planity/webview';
import React from 'react';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Layout } from '../layout';
import { Localize } from '@planity/components';
import { Image } from '@planity/ui';
import { useRandomImages } from '@planity/helpers/useRandomImages';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import styles from './unknown_url_page.module.scss';

export function UnknownURLPage() {
	const { routes } = useLocalizedRoutes();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		unknown: true
	});

	const { locale } = useLocalization();
	const isGermany = locale === 'de-DE';
	const queryParameterCampaign = 'utm_source=planity&utm_campaign=404';

	const imageUrl = useRandomImages();
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) {
					if (locale === 'nl-BE') {
						staticContext.status = 410;
						staticContext.url = '/fr-BE';
						return null;
					} else {
						staticContext.status = 404;
					}
				}
				return (
					<Layout
						crumbName={'pageNotFound'}
						hideBreadcrumbs
						hideFooter
						isDark
						showAppTeaser
						skipCoronavirusModal
					>
						<div className={classes}>
							<div className={styles.columns}>
								<div className={styles.container}>
									<div className={styles.title}>
										<h1>
											<Localize text={'nav.pageReallyNotFound'} />
											<span>
												&nbsp;
												<Localize text={'nav.errorCode'} />
											</span>
										</h1>
									</div>

									<h2>
										<Localize text={'nav.usefulLinks'} />
									</h2>
									<div className={styles.links}>
										<Link to={routes.home}>
											<Localize text={'nav.home'} />
										</Link>
										<Link to={routes.myAccount}>
											<Localize text={'nav.myAccount'} />
										</Link>
										{!isNativeApp && (
											<a
												href={
													isGermany
														? `${routes.becomePartnerBasePath}?${queryParameterCampaign}`
														: `${routes.becomePartner}?${queryParameterCampaign}`
												}
												rel='noreferrer'
												target='_blank'
											>
												<Localize text={'nav.becomePartner'} />
											</a>
										)}
									</div>
								</div>
								<div className={styles.image}>
									<Image fit='cover' isCloudinary url={imageUrl} />
								</div>
							</div>
						</div>
					</Layout>
				);
			}}
		/>
	);
}
